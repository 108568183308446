import WSBox from "components/WSBox";
import WSTypography from "components/WSTypography";
import Icon from "@mui/material/Icon";
import TimelineItem from "modules/Timeline/TimelineItem";
import Card from "@mui/material/Card";
import {CardMedia} from "@mui/material";
import brucemars from "assets/images/bruce-mars.jpg";
import Divider from "@mui/material/Divider";
function Introduction(){
    const styles = {
        media: {
            height: "100%",
            padding: "56.25px", // 16:9,
            boxShadow:"10px 10px 5px 0px rgba(0,0,0,0.75)",
            opacity:"0.95"
        }
    };
    return(
        <Card style={styles.media}>
            <WSTypography color={"error"} sx={{fontFamily: `Georgia`,fontWeight:"bold",fontSize:"XXL"}}>Introduction</WSTypography>
            <WSTypography sx={{fontFamily: "Georgia",fontWeight:"bold"}}>Hello, My Name is Mehrdad Kheirmand.</WSTypography>
            <WSTypography sx={{fontFamily: `Helvetica`,marginBottom:"10px"}}>
                I'm an experienced computer engineer and full-stack developer
                with nearly 5 years of experience in crafting digital experiences.
                I love blending tech and creativity to come up with
                innovative solutions. Turning ideas into reality is what I truly enjoy!</WSTypography>
            <WSTypography color={"error"} sx={{fontFamily: `Georgia`,fontWeight:"bold",fontSize:"XXL"}}>Projects That Define Me</WSTypography>
            <WSTypography sx={{fontFamily: `Helvetica`}}>
                🌐 WebSite Development: Explored the interactive nature
                of web applications where user experience is dynamic.
                From e-commerce platforms to management panels,
                I've done many disparate projects in that regard.</WSTypography>

            <Divider/>
            <WSTypography  sx={{fontFamily: `Helvetica`}}>🤖 AI Marvels: 
            I'm into AI stuff and have worked on some cool projects. 
            I messed around with machine learning and language stuff. 
            Excited about making smart things. 
            Ready for the next tech wave!</WSTypography>
        </Card>
    );
}


export default Introduction;