import {useState, useEffect, useRef} from "react";

// react-router components
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";


// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";


import WSBox from "components/WSBox";
import WSInput from "components/WSInput";

import Breadcrumbs from "modules/Breadcrumbs";
import NotificationItem from "modules/Items/NotificationItem";


//Material Icons
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';


import {
    navbar,
    navbarContainer,
    navbarRow,
    navbarIconButton,
    navbarMobileMenu,
} from "modules/Navbars/DashboardNavbar/styles";


import {
    useMaterialUIController,
    setTransparentNavbar,
    setMiniSidenav,
} from "context";
import WSButton from "../../../components/WSButton";
import WSTypography from "../../../components/WSTypography";
import Resume from "../../../assets/resume/MehrdadKheirmand-cv.pdf";


function DashboardNavbar({ absolute, light, isMini }) {
    const [navbarType, setNavbarType] = useState();
    const [controller, dispatch] = useMaterialUIController();
    const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
    const [openMenu, setOpenMenu] = useState(false);
    const route = useLocation().pathname.split("/").slice(1);

    useEffect(() => {
        // Setting the navbar type
        if (fixedNavbar) {
            setNavbarType("sticky");
        } else {
            setNavbarType("static");
        }

        // A function that sets the transparent state of the navbar.
        function handleTransparentNavbar() {
            setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
        }

        /**
         The event listener that's calling the handleTransparentNavbar function when
         scrolling the window.
         */
        window.addEventListener("scroll", handleTransparentNavbar);

        // Call the handleTransparentNavbar function to set the state with the initial value.
        handleTransparentNavbar();

        // Remove event listener on cleanup
        return () => window.removeEventListener("scroll", handleTransparentNavbar);
    }, [dispatch, fixedNavbar]);

    const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
    const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
    const handleCloseMenu = () => setOpenMenu(false);

    const valueRef = useRef('');
    const handleSearch = () =>{
        const link = document.createElement("a");
        link.search = valueRef;
        link.href = 'www.google.com';
        link.click();
    };
    // Styles for the navbar icons
    const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
        color: () => {
            let colorValue = light || darkMode ? white.main : dark.main;

            if (transparentNavbar && !light) {
                colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
            }

            return colorValue;
        },
    });

    return (
        <AppBar
            position={absolute ? "absolute" : navbarType}
            color="inherit"
            sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
        >
            <Toolbar sx={(theme) => navbarContainer(theme)}>
                <WSBox color="white" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
                    <Breadcrumbs  icon= {<HomeIcon sx={iconsStyle} />} title={route[route.length - 1]} route={route} light={light} />
                </WSBox>
                {isMini ? null : (
                    <WSBox sx={(theme) => navbarRow(theme, { isMini })}>
                        <WSBox display="flex"
                               justifyContent="space-between"
                               alignItems="center"
                        >
                            <WSInput label="Search here" variant={"outlined"} sx={{mr:"1rem"}} inputRef = {valueRef}/>
                            <WSButton target = {"_blank"} href= {`https://google.com/search?sitesearch=mehrdadkheirmand.com&q=${valueRef.current.value}`} color={"dark"} size={"small"} variant={"outlined"} ><WSTypography color={"white"}>Search</WSTypography></WSButton>
                        </WSBox>
                        <WSBox color={light ? "white" : "inherit"}>
                            <IconButton
                                size="small"
                                disableRipple
                                color="inherit"
                                sx={navbarMobileMenu}
                                onClick={handleMiniSidenav}
                            >
                                {miniSidenav ? <MenuOpenIcon sx={iconsStyle} fontSize="large"/> : <MenuIcon sx={iconsStyle} fontSize="large"/>}
                            </IconButton>
                        </WSBox>
                    </WSBox>
                )}
            </Toolbar>
        </AppBar>
    );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
    absolute: false,
    light: false,
    isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
    absolute: PropTypes.bool,
    light: PropTypes.bool,
    isMini: PropTypes.bool,
};

export default DashboardNavbar;