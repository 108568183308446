import WSBox from "components/WSBox";
import WSTypography from "components/WSTypography";
import Icon from "@mui/material/Icon";
import TimelineItem from "modules/Timeline/TimelineItem";
import Card from "@mui/material/Card";
import {CardMedia} from "@mui/material";
import mehrdadkheirmand from "assets/images/mehrdad-kheirmand-pic.JPG";
function HomePicture(){
    const CardMerdiastyles = {
        media: {
            /*height: "100%",
            paddingTop: "70%", // 16:9,
            margin:"10%",
            boxShadow:"10px 10px 5px 0px rgba(0,0,0,0.75)",
            objectFit:"cover"*/
            height: "100%",
            paddingTop: "70%", // 16:9,
            margin:"0%",
            boxShadow:"10px 10px 5px 0px rgba(0,0,0,0.75)",
            cursor:"pointer",
            objectFit:"contain",
            '&:hover':{
                transition: 'opacity 0.3s ease-in-out'
            },
        }
    };

    const Cardstyles = {
        media: {
            height: "100%",
            boxShadow:"10px 10px 5px 0px rgba(0,0,0,0.75) white inset",
            opacity:"0.95",// 16:9,
            marginTop:"20%",
        }
    };


    return(
        <Card style={Cardstyles.media}>
            <CardMedia
                image={mehrdadkheirmand} // require image
                title= "Mehrdad Kheirmand"
                style={CardMerdiastyles.media} // specify styles
            />
        </Card>

    );
}


export default HomePicture;