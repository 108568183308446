import WSBox from "components/WSBox";
import WSTypography from "components/WSTypography";
import Icon from "@mui/material/Icon";
import TimelineItem from "modules/Timeline/TimelineItem";
import Card from "@mui/material/Card";
import {CardMedia} from "@mui/material";
import brucemars from "assets/images/bruce-mars.jpg";
import Divider from "@mui/material/Divider";

import SchoolIcon from '@mui/icons-material/School';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import EmailIcon from "@mui/icons-material/Email";
import LanguageIcon from "@mui/icons-material/Language";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";

function EducationHistory(){

    const styles = {
        media: {
            height: "100%",
            padding: "30px", // 16:9,
            boxShadow:"10px 10px 5px 0px rgba(0,0,0,0.75)",
            opacity:"0.95",
        }
    };

    const typographyStyles = {
        media: {fontFamily: `Georgia`,fontWeight:"bold",fontSize:"XXL"}
    };
    const IconStyles = {
        media: {color:"white",fontsize: "200"}
    };

    return(
        <Card style={styles.media}>
            <WSBox>
                <WSTypography color={"error"} sx={typographyStyles.media}>Education</WSTypography>
            </WSBox>
            <WSBox display="flex" flexDirection={"column"} alignItems="center" >

                <SchoolIcon style={IconStyles.media}/>
                <WSTypography m={"10px"} sx={typographyStyles.media}>Shiraz University of Technology</WSTypography>
                <WSTypography m={"10px"} sx={typographyStyles.media}>Shiraz, Fars, Iran </WSTypography>
                <WSTypography m={"10px"} sx={typographyStyles.media}>Bachelor of Computer Engineering</WSTypography>
                <WSTypography m={"10px"} sx={typographyStyles.media}>Sept 2015 - April 2020</WSTypography>
            </WSBox>
            <Divider/>
            <WSBox display="flex" flexDirection={"column"} alignItems="center">
                <HistoryEduIcon style={IconStyles.media}/>
                <WSTypography m={"10px"} sx={typographyStyles.media}>National Bureau for the establishment of remarkable talents</WSTypography>
                <WSTypography m={"10px"} sx={typographyStyles.media}>Sirjan, Kerman, Iran </WSTypography>
                <WSTypography m={"10px"} sx={typographyStyles.media}>High School Diploma</WSTypography>
                <WSTypography m={"10px"} sx={typographyStyles.media}>Sept 2011 - April 2015</WSTypography>
            </WSBox>
        </Card>
    );
}


export default EducationHistory;