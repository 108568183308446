
function BlogData() {


    return [
        {
            "id": 1,
            "picture": "termux",
            "date": "Aug 2023",
            "tag": "linux",
            "title": "What is Termux ?",
            "link": "/blog/termux",
            "summary": "a simple introduction of a useful considerable application."
        },
        {
            "id": 2,
            "picture": "helloworld",
            "date": "July 2023",
            "tag": "hello world",
            "title": "Hello World",
            "link": "/blog/helloworld",
            "summary": "Hello. This is my first blog post of the portfolio. I am looking forward to share more ;))."
        },
    ]
}

export default BlogData;
