import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for WSInput
import WSInputRoot from "components/WSInput/WSInputRoot";

const WSInput = forwardRef(({ error, success, disabled, ...rest }, ref) => (
    <WSInputRoot {...rest} ref={ref} ownerState={{ error, success, disabled }} />
));

// Setting default values for the props of WSInput
WSInput.defaultProps = {
    error: false,
    success: false,
    disabled: false,
};

// Typechecking props for the WSInput
WSInput.propTypes = {
    error: PropTypes.bool,
    success: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default WSInput;