import WSBox from "components/WSBox";
import WSTypography from "components/WSTypography";
import Icon from "@mui/material/Icon";
import TimelineItem from "modules/Timeline/TimelineItem";
import Card from "@mui/material/Card";
import {CardMedia} from "@mui/material";
import brucemars from "assets/images/bruce-mars.jpg";
import Divider from "@mui/material/Divider";
import WSInput from "../../../../components/WSInput";
import WSButton from "../../../../components/WSButton";

import SendIcon from '@mui/icons-material/Send';
import {useEffect, useState} from "react";
import WSSnackbar from "../../../../components/WSSnackbar";

import CloseIcon from '@mui/icons-material/Close';

function Map(){
    const [infoSB, setInfoSB] = useState(false);
    const [inputValues, setInputValues] = useState({
        field1: '',
        field2: '',
        field3: '',
    });

    const handleInputChange = (field) => (event) => {
        setInputValues({
            ...inputValues,
            [field]: event.target.value,
        });
    };

    const areAllFieldsFilled = () => {
        // Check if all fields have values
        return Object.values(inputValues).every((value) => value.trim() !== '');
    };

    const OpenInfoSB = () => {
            setTimeout(()=>{
                setInfoSB(true);
            }, 3500);
    }
    const closeInfoSB = () => setInfoSB(false);

    const renderInfoSB = (
        <WSSnackbar
            color="success"
            icon="notifications"
            title="Notification"
            content="Message was successfully sent."
            open={infoSB}
            onClose={closeInfoSB}
            close={closeInfoSB}
        />
    );

    const styles = {
        media: {
            height: "100%",
            padding: "56.25px", // 16:9,
            boxShadow:"10px 10px 5px 0px rgba(0,0,0,0.75)",
            opacity:"0.95"
        }
    };
    return(
        <Card style={styles.media}>
            <WSTypography color={"error"} sx={{fontFamily: `Georgia`,fontWeight:"bold",fontSize:"XXL"}}>Let's Connect and Collaborate</WSTypography>
            <WSTypography sx={{fontFamily: `Helvetica`}}>I'm not just a developer;
                I'm a collaborator. Whether you're a fellow tech enthusiast,
                a potential collaborator, or someone looking to bring a digital vision to life,
                I invite you to connect. Let's embark on a journey where creativity meets functionality,
                and ideas turn into immersive digital experiences.
                Ready to embark on this digital adventure together? Dive into my portfolio, explore the projects, and let's create something extraordinary.
                Cheers to coding, creativity, and endless possibilities! </WSTypography>

            <Divider/>
            <WSBox display="flex"
                   justifyContent="center"
                   alignItems="center"
                   sx={{mb:"20px"}}
            >
                <WSInput label="Full Name" variant={"outlined"} sx={{mr:"1rem"}} value={inputValues.field1} onChange={handleInputChange('field1')}/>
                <WSInput label="Email Address" variant={"outlined"} sx={{mr:"1rem"}} value={inputValues.field2} onChange={handleInputChange('field2')}/>


            </WSBox>
            <WSBox display="flex"
                   justifyContent="center"
                   alignItems="center"
                   sx={{mb:"20px"}}
            >

                <WSInput label="Your Message" variant={"outlined"}
                         sx={{ width: '100%', maxWidth: '100%', multiline:"true",rows:"4"}}
                         fullWidth
                         value={inputValues.field3}
                         onChange={handleInputChange('field3')}
                />
            </WSBox>
            <WSBox>
                <WSButton color={"success"} size={"large"} variant={"gradient"} onClick={OpenInfoSB} disabled={!areAllFieldsFilled()}>
                    <WSTypography color={"white"} m={"10px"}>Send Message</WSTypography>
                    <SendIcon/>
                </WSButton>
                {renderInfoSB}
            </WSBox>

        </Card>
    );
}


export default Map;