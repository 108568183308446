/**
Once you add a new route on this file it will be visible automatically on
the Sidenav.
For adding a new route you can follow the existing routes in the routes array.
1. The `type` key with the `collapse` value is used for a route.
2. The `type` key with the `title` value is used for a title inside the Sidenav.
3. The `type` key with the `divider` value is used for a divider between Sidenav items.
4. The `name` key is used for the name of the route on the Sidenav.
5. The `key` key is for the key of the route (It will help you with the key prop inside a loop).
6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
8. The `route` key is used to store the route location which is used for the react router.
9. The `href` key is used to store the external links location.
10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
10. The `component` key is used to store the component of its route.
*/


// Material Dashboard 2 React layouts
import Home from "layouts/home";
import About from "layouts/about";
import Blog from "layouts/blog";
import Message from "layouts/contact";
import ProjectsPage from "./layouts/projects";
// @mui icons
import HomeIcon from "@mui/icons-material/Home";
import NewspaperIcon from '@mui/icons-material/Newspaper';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import InfoIcon from '@mui/icons-material/Info';
import EmailIcon from '@mui/icons-material/Email';
import TermuxTemplate from "./layouts/blog/BlogTemplates/Termux/";
import HWTemplate from "./layouts/blog/BlogTemplates/HelloWorld";




const routes = [
    {
        type: "collapse",
        name: "Home",
        key: "home",
        icon: <HomeIcon fontSize={"medium"} />,
        route: "/",
        component: <Home />,
    },
  /*  {
        type: "collapse",
        name: "Projects",
        key: "projects",
        icon: <AccountTreeIcon fontSize={"medium"}/>,
        route: "/projects",
        component: <ProjectsPage />,
    },*/
    {
        type: "collapse",
        name: "Blog",
        key: "blog",
        icon: <NewspaperIcon fontSize={"medium"}/>,
        route: "/blog",
        component: <Blog />,
    },
    {
        type: "collapse",
        name: "About Me",
        key: "about me",
        icon: <InfoIcon fontSize={"medium"}/>,
        route: "/about",
        component: <About />,
    },
    {
        type: "collapse",
        name: "Message",
        key: "message",
        icon:<EmailIcon fontSize={"medium"}/>,
        route: "/message",
        component: <Message />,
    },
    {
        type: "title",
        name: "Blog",
        key: "termux",
        icon: <NewspaperIcon fontSize={"medium"}/>,
        route: "/blog/termux",
        component: <TermuxTemplate route= "termux"/>,
    },
    {
        type: "title",
        name: "Blog",
        key: "helloworld",
        icon: <NewspaperIcon fontSize={"medium"}/>,
        route: "/blog/helloworld",
        component: <HWTemplate route= "helloworld"/>,
    },
];

export default routes;