import React from "react";
import {ClockLoader,GridLoader} from "react-spinners";
import WSBox from "../../components/WSBox";
import Logo from "assets/images/mehrdad-kheirmand-logo.svg";
import Logo1 from "assets/images/mehrdad-kheirmand-logo-1.png";
import Logo2 from "assets/images/mehrdad-kheirmand-logo-2.png";
import Logo3 from "assets/images/rsz_mehrdad-kheirmand-logo-1.png";
import Logo4 from "assets/images/mehrdad-kheirmand-pic.JPG";
import {CardMedia} from "@mui/material";
import Card from "@mui/material/Card";
import mehrdadkheirmand from "../../assets/images/mehrdad-kheirmand-pic.JPG";
function Splash({isLoading}) {

    const Cardstyles = {
        media: {
            height: "100%",
            boxShadow:"10px 10px 5px 0px rgba(0,0,0,0.75) white inset",
            opacity:"0.95",// 16:9,
            marginTop:"20%",

        }
    };

    const styles = {
        baseStyles: {
            height:"100vh",
            display: "flex",
            flexDirection:"column",
            justifyContent:"center",
            alignItems:"center",
            background:"#121a28",
        },
        gridLoaderStyles:{
            display:"white",
            margin:"0 auto",

        },
        LogoStyles:{
            width:"60%",
            paddingTop: "10%", // 16:9,
            margin:"5%",
        }

    };
    return (
        <div style={styles.baseStyles}>
                <CardMedia
                    image={Logo3} // require image
                    style={styles.LogoStyles} // specify styles
                />

                <GridLoader color={"#CBB9A8"} isLoading={isLoading} style={styles.gridLoaderStyles}/>
        </div>
    );

}

export default Splash;