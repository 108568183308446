import { useMemo } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

import ScheduleIcon from '@mui/icons-material/Schedule';
import WSBox from "components/WSBox";
import WSTypography from "components/WSTypography";
import {CardMedia} from "@mui/material";
import brucemars from "../../../assets/images/bruce-mars.jpg";


function ProjectCard({ color, title, description, date, chart }) {

    const CardMerdiastyles = {
        media: {
            height: "100%",
            paddingTop: "70%", // 16:9,
            margin:"10%",
            boxShadow:"10px 10px 5px 0px rgba(0,0,0,0.75)",

        }
    };


    return (
        <Card sx={{ height: "100%" }}>
            <WSBox padding="1rem">
                {useMemo(
                    () => (
                        <WSBox
                        >
                            <CardMedia
                                image={brucemars}
                                style={CardMerdiastyles.media} // specify styles
                            />
                        </WSBox>
                    ),
                    [chart, color]
                )}
                <WSBox pt={3} pb={1} px={1}>
                    <WSTypography variant="h6" textTransform="capitalize">
                        {title}
                    </WSTypography>
                    <WSTypography component="div" variant="button" color="text" fontWeight="light">
                        {description}
                    </WSTypography>
                    <Divider />
                    <WSBox display="flex" alignItems="center">
                        <WSTypography variant="button" color="text" lineHeight={1} sx={{ mt: 0.15, mr: 0.5 }}>
                            <ScheduleIcon />
                        </WSTypography>
                        <WSTypography variant="button" color="text" fontWeight="light">
                            {date}
                        </WSTypography>
                    </WSBox>
                </WSBox>
            </WSBox>
        </Card>
    );
}

// Setting default values for the props of ReportsLineChart
ProjectCard.defaultProps = {
    color: "dark",
    description: "",
};

// Typechecking props for the ReportsLineChart
ProjectCard.propTypes = {
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
    title: PropTypes.string.isRequired,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    date: PropTypes.string.isRequired,
    chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
};

export default ProjectCard;