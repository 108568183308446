
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import WSBox from "components/WSBox";

// Material Dashboard 2 React example components
import DashboardLayout from "modules/LayoutContainers/DashboardLayout";
import DashboardNavbar from "modules/Navbars/DashboardNavbar";

// Data

// Dashboard components
import OrdersOverview from "layouts/home/components/OrdersOverview";
import HomePicture from "layouts/home/components/HomePicture";
import EducationHistory from "./components/EducationHistory";
import ContactData from "./components/ContactData";
import JobExperience from "./components/JobExperience";
import Projects from "./components/ProjectsShowCase";
import ProgrammingLanguage from "./components/Programming Languages";
import Skills from "./components/Skills";
function About() {

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <WSBox py={3}>
                <WSBox mb={"10px"}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={8}>
                            <EducationHistory/>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <ContactData />
                        </Grid>
                    </Grid>
                </WSBox>
                <WSBox mb={"10px"}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={6}>
                            <JobExperience />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <Projects />
                        </Grid>
                    </Grid>
                </WSBox>
                <WSBox>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={6}>
                            <ProgrammingLanguage />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <Skills />
                        </Grid>
                    </Grid>
                </WSBox>
            </WSBox>
        </DashboardLayout>
    );
}

export default About;
