import WSBox from "components/WSBox";
import WSTypography from "components/WSTypography";
import Icon from "@mui/material/Icon";
import TimelineItem from "modules/Timeline/TimelineItem";
import Card from "@mui/material/Card";
import {CardMedia, Pagination} from "@mui/material";
import brucemars from "assets/images/bruce-mars.jpg";
import Divider from "@mui/material/Divider";
import DashboardNavbar from "../../modules/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";
import EducationHistory from "../about/components/EducationHistory";
import ContactData from "../about/components/ContactData";
import JobExperience from "../about/components/JobExperience";
import Projects from "../about/components/ProjectsShowCase";
import DashboardLayout from "../../modules/LayoutContainers/DashboardLayout";
import ProjectCard from "./ProjectCard";
function ProjectsPage(){
    const styles = {
        media: {
            height: "100%",
            padding: "56.25px", // 16:9,
            boxShadow:"10px 10px 5px 0px rgba(0,0,0,0.75)",
            opacity:"0.95"
        }
    };
    return(
        <DashboardLayout>
            <DashboardNavbar />
            <WSBox py={3}>
                <WSBox mb={"10px"}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={4}>
                            <ProjectCard date={"March 2023"} title={"blah blah blah"} description={"Dlawbjfklabwkljdnbklajwbfklcjabdkjabwd"}/>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <ProjectCard date={"March 2023"} title={"blah blah blah"} description={"Dlawbjfklabwkljdnbklajwbfklcjabdkjabwd"}/>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <ProjectCard date={"March 2023"} title={"blah blah blah"} description={"Dlawbjfklabwkljdnbklajwbfklcjabdkjabwd"}/>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <ProjectCard date={"March 2023"} title={"blah blah blah"} description={"Dlawbjfklabwkljdnbklajwbfklcjabdkjabwd"}/>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <ProjectCard date={"March 2023"} title={"blah blah blah"} description={"Dlawbjfklabwkljdnbklajwbfklcjabdkjabwd"}/>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <ProjectCard date={"March 2023"} title={"blah blah blah"} description={"Dlawbjfklabwkljdnbklajwbfklcjabdkjabwd"}/>
                        </Grid>
                    </Grid>
                </WSBox>
                <Pagination count={10} color="secondary" />
            </WSBox>
        </DashboardLayout>
    );
}


export default ProjectsPage;