import WSBox from "components/WSBox";
import WSTypography from "components/WSTypography";
import Icon from "@mui/material/Icon";
import TimelineItem from "modules/Timeline/TimelineItem";
import Card from "@mui/material/Card";
import {CardMedia} from "@mui/material";
import brucemars from "assets/images/bruce-mars.jpg";
import Divider from "@mui/material/Divider";

import EmailIcon from '@mui/icons-material/Email';
import LanguageIcon from '@mui/icons-material/Language';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import TwitterIcon from "@mui/icons-material/Twitter";

function ContactData(){
    const styles = {
        media: {
            height: "100%",
            padding: "30px", // 16:9,
            boxShadow:"10px 10px 5px 0px rgba(0,0,0,0.75)",
            opacity:"0.95",
        }
    };

    const typographyStyles = {
        media: {fontFamily: `Georgia`,fontWeight:"bold",fontSize:"XXL"}
    };
    const IconStyles = {
        media: {color:"white"}
    };

    return(
        <Card style={styles.media}>
            <WSBox mb={"50px"}>
                <WSTypography color={"error"} sx={typographyStyles.media}>Contact me</WSTypography>
            </WSBox>
            <WSBox display="flex" alignItems="center" >
                <EmailIcon style={IconStyles.media}/>
                <WSTypography m={"10px"} sx={typographyStyles.media}>mithradates23@gmail.com</WSTypography>
            </WSBox>
            <Divider/>
            <WSBox display="flex" alignItems="center">
                <LanguageIcon style={IconStyles.media}/>
                <WSTypography m={"10px"} sx={typographyStyles.media}>mehrdadkheirmand.com</WSTypography>
            </WSBox>
            <Divider/>
            <WSBox display="flex" alignItems="center">
                <TwitterIcon style={IconStyles.media}/>
                <WSTypography m={"10px"} sx={typographyStyles.media}>mehrdad11923943</WSTypography>
            </WSBox>
            <Divider/>
            <WSBox display="flex" alignItems="center">
                <LinkedInIcon style={IconStyles.media}/>
                <WSTypography m={"10px"} sx={typographyStyles.media}>mehrdad kheirmand</WSTypography>
            </WSBox>
            <Divider/>
            <WSBox display="flex" alignItems="center">
                <AlternateEmailIcon style={IconStyles.media}/>
                <WSTypography m={"10px"} sx={typographyStyles.media}>stackoverflow: mehrdad</WSTypography>
            </WSBox>
        </Card>
    );
}


export default ContactData;