import { useMemo ,useState,useEffect} from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

import ScheduleIcon from '@mui/icons-material/Schedule';
import WSBox from "components/WSBox";
import WSTypography from "components/WSTypography";
import {CardMedia} from "@mui/material";
import brucemars from "assets/images/bruce-mars.jpg";
import {hover} from "@testing-library/user-event/dist/hover";
import rgba from "../../../assets/theme-dark/functions/rgba";

import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import BlogTemplate from "../BlogTemplates/Termux";
import {Link} from "react-router-dom";

function BlogCard({ color ,picture, title, description, date ,chart,link,tag}) {


    const CardMerdiastyles = {
        media: {
            height: "100%",
            paddingTop: "70%", // 16:9,
            margin:"0%",
            boxShadow:"10px 10px 5px 0px rgba(0,0,0,0.75)",
            cursor:"pointer",
            objectFit:"contain",
            '&:hover':{
                transition: 'opacity 0.3s ease-in-out'
            },
        },
    };

   /* const [imageSource, setImageSource] = useState(null);
    useEffect(() => {
        const importImage = async () => {
            try {
                const importedImage = await import(`${picture}`);
                setImageSource(importedImage.default);
            } catch (error) {
                console.error(`Error importing image for ${picture}:`, error);
            }
        };

        importImage();
    }, [picture]);
    */

    return (
        <Card sx={{ height: "100%", backgroundColor:rgba("#2c3c58", 0.95)}}>
            <WSBox padding="1rem">
                <WSBox>
                    <Link to= {link}>
                        <CardMedia
                            role="link"
                            image= {picture}
                            style={CardMerdiastyles.media}
                        />
                    </Link>
                </WSBox>
                <WSBox pt={3} pb={1} px={1}>
                    <WSTypography variant="h6" textTransform="capitalize">
                        {title}
                    </WSTypography>
                    <WSTypography pb={1} component="div" variant="button" color="text" fontWeight="light">
                        <LocalOfferIcon sx={{paddingTop : "1px",marginRight:"5px"}}/>
                        {tag}
                    </WSTypography>
                    <WSTypography component="div" variant="button" color="text" fontWeight="light">
                        {description}
                    </WSTypography>
                    <Divider />
                    <WSBox display="flex" alignItems="center">
                        <WSTypography variant="button" color="text" lineHeight={1} sx={{ mt: 0.15, mr: 0.5 }}>
                            <ScheduleIcon />
                        </WSTypography>
                        <WSTypography variant="button" color="text" fontWeight="light">
                            {date}
                        </WSTypography>
                    </WSBox>
                </WSBox>
            </WSBox>
        </Card>
    );
}

// Setting default values for the props of ReportsLineChart
BlogCard.defaultProps = {
    color: "dark",
    description: "",
};

// Typechecking props for the ReportsLineChart
BlogCard.propTypes = {
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
    title: PropTypes.string.isRequired,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    date: PropTypes.string.isRequired,
    chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])),
};

export default BlogCard;
