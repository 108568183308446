import React, { useState,useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";

import WSBox from "components/WSBox";


import DashboardLayout from "modules/LayoutContainers/DashboardLayout";
import DashboardNavbar from "modules/Navbars/DashboardNavbar";



import OrdersOverview from "layouts/home/components/OrdersOverview";
import HomePicture from "layouts/home/components/HomePicture";
import Introduction from "./components/Introduction";
function Home() {


  return (
      <DashboardLayout>
          <DashboardNavbar />
          <WSBox py={3}>
              <WSBox>
                  <Grid container spacing={3}>
                      <Grid item xs={12} md={6} lg={9}>
                          <Introduction />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                          <HomePicture />
                      </Grid>
                  </Grid>
              </WSBox>
          </WSBox>
      </DashboardLayout>
    );
}

export default Home;
