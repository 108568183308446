import WSBox from "components/WSBox";
import WSTypography from "components/WSTypography";
import Icon from "@mui/material/Icon";
import TimelineItem from "modules/Timeline/TimelineItem";
import Card from "@mui/material/Card";
import {CardMedia} from "@mui/material";
import brucemars from "assets/images/bruce-mars.jpg";
import Divider from "@mui/material/Divider";
import WSProgress from "../../../../components/WSProgress";

import GppGoodIcon from '@mui/icons-material/GppGood';

function ProgrammingLanguage(){
    const styles = {
        media: {
            height: "100%",
            padding: "30px", // 16:9,
            boxShadow:"10px 10px 5px 0px rgba(0,0,0,0.75)",
            opacity:"0.95",
        }
    };
    return(
        <Card style={styles.media}>
            <WSTypography color={"error"} mb={"20px"} sx={{fontFamily: `Georgia`,fontWeight:"bold",fontSize:"XXL"}}>Programming Language Knowledge</WSTypography>
            <WSBox display={"flex"}>
                <WSTypography sx={{fontFamily: `Helvetica`,fontSize:"XXL"}}>C#</WSTypography>
                <GppGoodIcon color={"white"} />
            </WSBox>
            <WSProgress value={90} color="success" variant="gradient"/>
            <Divider/>
            <WSBox display={"flex"}>
                <WSTypography sx={{fontFamily: `Helvetica`,fontSize:"XXL"}}>Python</WSTypography>
                <GppGoodIcon color={"white"} />
            </WSBox>
            <WSProgress value={80} color="success" variant="gradient"/>
            <Divider/>
            <WSTypography sx={{fontFamily: `Helvetica`,fontSize:"XXL"}}>C/C++</WSTypography>
            <WSProgress value={45} color="success" variant="gradient"/>
            <Divider/>
            <WSTypography sx={{fontFamily: `Helvetica`,fontSize:"XXL"}}>Java</WSTypography>
            <WSProgress value={70} color="success" variant="gradient"/>
            <Divider/>
            <WSBox display={"flex"}>
                <WSTypography sx={{fontFamily: `Helvetica`,fontSize:"XXL"}}>Javascript/Typescript</WSTypography>
                <GppGoodIcon color={"white"} />
            </WSBox>
            <WSProgress value={75} color="success" variant="gradient"/>
            <Divider/>
            <WSTypography sx={{fontFamily: `Helvetica`,fontSize:"XXL"}}>Matlab</WSTypography>
            <WSProgress value={30} color="success" variant="gradient"/>
            <Divider/>
            <WSTypography sx={{fontFamily: `Helvetica`,fontSize:"XXL"}}>Assembly(x86)</WSTypography>
            <WSProgress value={40} color="success" variant="gradient"/>

        </Card>
    );
}


export default ProgrammingLanguage;