import WSBox from "components/WSBox";
import WSTypography from "components/WSTypography";
import Icon from "@mui/material/Icon";
import TimelineItem from "modules/Timeline/TimelineItem";
import Card from "@mui/material/Card";
import {CardMedia} from "@mui/material";
import brucemars from "assets/images/bruce-mars.jpg";
import Divider from "@mui/material/Divider";
import WSProgress from "../../../../components/WSProgress";
function Skills(){
    const styles = {
        media: {
            height: "100%",
            padding: "30px", // 16:9,
            boxShadow:"10px 10px 5px 0px rgba(0,0,0,0.75)",
            opacity:"0.95"
        }
    };
    return(
        <Card style={styles.media}>
            <WSTypography color={"error"} mb={"20px"} sx={{fontFamily: `Georgia`,fontWeight:"bold",fontSize:"XXL"}}>Areas of Expertise</WSTypography>
            <WSTypography sx={{fontFamily: `Helvetica`}}>Web Development</WSTypography>
            <WSProgress value={80} color="success" variant="gradient"/>
            <Divider/>
            <WSTypography sx={{fontFamily: `Helvetica`}}>Reverse Engineering</WSTypography>
            <WSProgress value={65} color="success" variant="gradient"/>
            <Divider/>
            <WSTypography sx={{fontFamily: `Helvetica`}}>Research</WSTypography>
            <WSProgress value={70} color="success" variant="gradient"/>
            <Divider/>
            <WSTypography sx={{fontFamily: `Helvetica`}}>Team management (Agile principles)</WSTypography>
            <WSProgress value={65} color="success" variant="gradient"/>
            <Divider/>
            <WSTypography sx={{fontFamily: `Helvetica`}}>Data Engineering</WSTypography>
            <WSProgress value={50} color="success" variant="gradient"/>
            <Divider/>
            <WSTypography sx={{fontFamily: `Helvetica`}}>Algorithms and Structures</WSTypography>
            <WSProgress value={65} color="success" variant="gradient"/>
            <Divider/>
            <WSTypography sx={{fontFamily: `Helvetica`,}}>Computer vision</WSTypography>
            <WSProgress value={50} color="success" variant="gradient"/>
            <Divider/>
            <WSTypography sx={{fontFamily: `Helvetica`}}>Machine learning</WSTypography>
            <WSProgress value={60} color="success" variant="gradient"/>
            <Divider/>
        </Card>
    );
}


export default Skills;