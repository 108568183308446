import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for WSAvatar
import WSAvatarRoot from "components/WSAvatar/WSAvatarRoot";


const WSAvatar = forwardRef(({ bgColor, size, shadow, ...rest }, ref) => (
    <WSAvatarRoot ref={ref} ownerState={{ shadow, bgColor, size }} {...rest} />
));

// Setting default values for the props of WSAvatar
WSAvatar.defaultProps = {
    bgColor: "transparent",
    size: "md",
    shadow: "none",
};

// Typechecking props for the WSAvatar
WSAvatar.propTypes = {
    bgColor: PropTypes.oneOf([
        "transparent",
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
    ]),
    size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl"]),
    shadow: PropTypes.oneOf(["none", "xs", "sm", "md", "lg", "xl", "xxl", "inset"]),
};

export default WSAvatar;
