
// Material Dashboard 2 React Base Styles
import colors from "assets/theme-dark/base/colors";
import backgroundImage from "assets/images/base-bg.png";
const background = `url(${backgroundImage})`;
const { info, dark } = colors;

const globals = {
  html: {
    scrollBehavior: "smooth",
  },
  "*, *::before, *::after": {
    margin: 0,
    padding: 0,
  },
  "a, a:link, a:visited": {
    textDecoration: "none !important",
  },
  "a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited": {
    color: `${dark.main} !important`,
    transition: "color 150ms ease-in !important",
  },
  "a.link:hover, .link:hover, a.link:focus, .link:focus": {
    color: `${info.main} !important`,
  },
  body: {
    //backgroundColor: "#0a0a0a"
    backgroundImage:background
    //backgroundImage: `URL('https://makeagif.com/i/VIxxNm')`
  }
  ,'*::-webkit-scrollbar': {
    width: '0.4em',
    borderRadius:"10px"
  },
  '*::-webkit-scrollbar-track': {
    '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    'borderRadius':"10px"
  },
  '*::-webkit-scrollbar-thumb': {
    width: '0.4rem',
    backgroundColor: 'rgba(0,0,0,.01)',
    //outline: '1px solid slategrey',
    borderRadius:"10px",
    opacity:"0.01",
    boxShadow:"inset 0 0 6px rgba(0,0,0,0.00)"
  }
};

export default globals;
