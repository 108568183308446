// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import Fade from "@mui/material/Fade";

// Material Dashboard 2 React components
import WSBox from "components/WSBox";
import WSTypography from "components/WSTypography";

// Custom styles for the WSSnackbar
import WSSnackbarIconRoot from "components/WSSnackbar/WSSnackbarIconRoot";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import CloseIcon from "@mui/icons-material/Close";

function WSSnackbar({ color, icon, title, dateTime, content, close, bgWhite, ...rest }) {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    let titleColor;
    let dateTimeColor;
    let dividerColor;

    if (bgWhite) {
        titleColor = color;
        dateTimeColor = "dark";
        dividerColor = false;
    } else if (color === "light") {
        titleColor = darkMode ? "inherit" : "dark";
        dateTimeColor = darkMode ? "inherit" : "text";
        dividerColor = false;
    } else {
        titleColor = "white";
        dateTimeColor = "white";
        dividerColor = true;
    }

    return (
        <Snackbar
            TransitionComponent={Fade}
            autoHideDuration={5000}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            {...rest}
            action={
                <IconButton size="small" aria-label="close" color="inherit" onClick={close}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            }
        >
            <WSBox
                variant={bgWhite ? "contained" : "gradient"}
                bgColor={bgWhite ? "white" : color}
                minWidth="21.875rem"
                maxWidth="100%"
                shadow="md"
                borderRadius="md"
                p={1}
                sx={{
                    backgroundColor: ({ palette }) =>
                        darkMode ? palette.background.card : palette[color] || palette.white.main,
                }}
            >
                <WSBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    color="dark"
                    p={1.5}
                >
                    <WSBox display="flex" alignItems="center" lineHeight={0}>
                        <WSTypography
                            variant="button"
                            fontWeight="medium"
                            color={titleColor}
                            textGradient={bgWhite}
                        >
                            {title}
                        </WSTypography>
                    </WSBox>
                    <WSBox display="flex" alignItems="center" lineHeight={0}>
                        <WSTypography variant="caption" color={dateTimeColor}>
                            {dateTime}
                        </WSTypography>
                        <CloseIcon
                            sx={{
                                color: ({ palette: { dark, white } }) =>
                                    (bgWhite && !darkMode) || color === "light" ? dark.main : white.main,
                                fontWeight: ({ typography: { fontWeightBold } }) => fontWeightBold,
                                cursor: "pointer",
                                marginLeft: 2,
                                transform: "translateY(-1px)",
                            }}
                            onClick={close}
                        />
                    </WSBox>
                </WSBox>
                <Divider sx={{ margin: 0 }} light={dividerColor} />
                <WSBox
                    p={1.5}
                    sx={{
                        fontSize: ({ typography: { size } }) => size.sm,
                        color: ({ palette: { white, text } }) => {
                            let colorValue = bgWhite || color === "light" ? text.main : white.main;

                            if (darkMode) {
                                colorValue = color === "light" ? "inherit" : white.main;
                            }

                            return colorValue;
                        },
                    }}
                >
                    {content}
                </WSBox>
            </WSBox>
        </Snackbar>
    );
}

// Setting default values for the props of WSSnackbar
WSSnackbar.defaultProps = {
    bgWhite: false,
    color: "info",
};

// Typechecking props for WSSnackbar
WSSnackbar.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
    ]),
    icon: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    dateTime: PropTypes.string.isRequired,
    content: PropTypes.node.isRequired,
    close: PropTypes.func.isRequired,
    bgWhite: PropTypes.bool,
};

export default WSSnackbar;