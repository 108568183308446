import DashboardLayout from "../../../../modules/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../modules/Navbars/DashboardNavbar";
import WSBox from "../../../../components/WSBox";
import Card from "@mui/material/Card";
import WSTypography from "../../../../components/WSTypography";
import Divider from "@mui/material/Divider";
import mehrdadkheirmand from "../../../../assets/images/termux-1.png";
import {CardMedia} from "@mui/material";

function TermuxTemplate ({headingPic,title,description,route}) {

    const styles = {
        media: {
            height: "100%",
            padding: "56.25px", // 16:9,
            boxShadow:"10px 10px 5px 0px rgba(0,0,0,0.75)",
            opacity:"0.95"
        },
        headerStyle: {
            fontFamily: `Helvetica`,fontWeight:"bold",fontSize:"XXL"
        },
        picMedia: {
            height: "100%",
            paddingTop: "40%", // 16:9,
            margin:"0%",
            cursor:"pointer",
            objectFit:"contain",
            '&:hover':{
                transition: 'opacity 0.3s ease-in-out'
            },
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <WSBox py={3}>
                <Card style={styles.media}>
                    <WSTypography color={"error"} sx={{fontFamily: `Georgia`,fontWeight:"bold",fontSize:"XXL",marginBottom:"20px"}}>What is TERMUX ?</WSTypography>
                    <CardMedia
                        image={mehrdadkheirmand} // require image
                        title= "Mehrdad Kheirmand"
                        style={styles.picMedia} // specify styles
                    />
                    <Divider/>
                    <WSTypography color={"error"} sx={{fontFamily: `Georgia`,fontWeight:"bold",fontSize:"XXL",marginBottom:"20px"}}>Introduction</WSTypography>
                    <WSTypography sx={{fontFamily: `Helvetica`}}> Termux is a free and open-source
                        terminal emulator for Android which enables users for running a
                        Linux environment on an Android device. Termux installs a
                        minimal base system automatically; additional packages are
                        available using its package manager, and are Debian based. It's essentially a powerful terminal emulator
                        that brings a Linux-like environment to your device of choice.
                    </WSTypography>
                    <Divider/>
                    <WSTypography color={"error"} sx={{fontFamily: `Georgia`,fontWeight:"bold",fontSize:"XXL",marginBottom:"20px"}}>Features & Abilities</WSTypography>
                    <WSTypography sx={{fontFamily: `Helvetica`,marginBottom:"20px"}}>
                        <span style={styles.headerStyle}>Terminal Emulator</span> : Termux provides a full Linux terminal
                        emulator on your Android device, allowing you to run commands just like you would on a Linux system.
                    </WSTypography>
                    <WSTypography sx={{fontFamily: `Helvetica`,marginBottom:"20px"}}>
                        <span style={styles.headerStyle}>Package Management</span> : It has its package management system, similar to APT on Debian-based systems.
                        This means you can easily install, update,
                        and remove software packages using commands
                        like "pkg install", "pkg upgrade", and "pkg uninstall".
                    </WSTypography>
                    <WSTypography sx={{fontFamily: `Helvetica`,marginBottom:"20px"}}>
                        <span style={styles.headerStyle}>Linux Utilities</span> : Termux comes with a variety of Linux utilities and commands,
                        ranging from basic ones like "ls" and "cd" to more advanced tools
                        like "git", "python", and even compilers for languages like C and C++.
                    </WSTypography>
                    <WSTypography sx={{fontFamily: `Helvetica`,marginBottom:"20px"}}>
                        <span style={styles.headerStyle}>Scripting and Automation</span> : You can use Termux to write and execute scripts,
                        making it a handy tool for automation or running repetitive tasks. This is particularly useful for
                        tasks that can be easily scripted using Bash
                        , Python, or other scripting languages.
                    </WSTypography>
                    <WSTypography sx={{fontFamily: `Helvetica`,marginBottom:"20px"}}>
                        <span style={styles.headerStyle}>Programming Environment</span> : Termux can serve as a lightweight development environment.
                        You can write code, compile programs,
                        and even run web servers locally on your Android device.
                    </WSTypography>
                    <WSTypography sx={{fontFamily: `Helvetica`,marginBottom:"20px"}}>
                        <span style={styles.headerStyle}>Penetration Testing</span> : For ethical hackers and security enthusiasts,
                        Termux provides a platform to perform various penetration testing tasks.
                        It includes tools for network scanning, password cracking, and more.
                    </WSTypography>
                    <WSTypography sx={{fontFamily: `Helvetica`,marginBottom:"20px"}}>
                        <span style={styles.headerStyle}>Learning Linux</span> : If you're new to Linux,
                        Termux offers a great way to learn and experiment without the need for a separate computer.
                        You can explore Linux commands, file systems,
                        and system administration tasks in a controlled environment.
                    </WSTypography>
                    <WSTypography sx={{fontFamily: `Helvetica`,marginBottom:"20px"}}>
                        <span style={styles.headerStyle}>SSH and Remote Access</span> : Termux supports SSH,
                        allowing you to connect to other devices remotely. This can be useful for managing servers, accessing files,
                        or even programming on a more powerful machine from your Android device.
                    </WSTypography>
                    <Divider />
                    <WSTypography sx={{fontFamily: `Helvetica`,marginBottom:"20px"}}>
                        Overall, Termux is a versatile tool that can be as simple or as powerful as you need it to be. Whether you're a developer,
                        a system administrator,
                        or just curious about Linux,
                        Termux provides a powerful platform that you may need in the future.
                    </WSTypography>
                    <WSTypography color={"success"} sx={{fontFamily: 'Georgia'}}>
                        Keep calm and code on, with a side of coffee ;)).
                    </WSTypography>

                </Card>

            </WSBox>
        </DashboardLayout>

    )
}

export default TermuxTemplate;