import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import WSTypography from "components/WSTypography";

// Custom styles for WSProgress
import WSProgressRoot from "components/WSProgress/WSProgressRoot";

const WSProgress = forwardRef(({ variant, color, value, label, ...rest }, ref) => (
    <>
        {label && (
            <WSTypography variant="button" fontWeight="medium" color="text">
                {value}%
            </WSTypography>
        )}
        <WSProgressRoot
            {...rest}
            ref={ref}
            variant="determinate"
            value={value}
            ownerState={{ color, value, variant }}
        />
    </>
));

// Setting default values for the props of WSProgress
WSProgress.defaultProps = {
    variant: "contained",
    color: "info",
    value: 0,
    label: false,
};

// Typechecking props for the WSProgress
WSProgress.propTypes = {
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
    ]),
    value: PropTypes.number,
    label: PropTypes.bool,
};

export default WSProgress;