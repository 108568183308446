import DashboardLayout from "../../../../modules/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../modules/Navbars/DashboardNavbar";
import WSBox from "../../../../components/WSBox";
import Card from "@mui/material/Card";
import WSTypography from "../../../../components/WSTypography";
import Divider from "@mui/material/Divider";
import helloworld from "../../../../assets/images/hello-world.png";
import {CardMedia} from "@mui/material";

function HWTemplate ({headingPic,title,description,route}) {

    const styles = {
        media: {
            height: "100%",
            padding: "56.25px", // 16:9,
            boxShadow:"10px 10px 5px 0px rgba(0,0,0,0.75)",
            opacity:"0.95"
        },
        headerStyle: {
            fontFamily: `Helvetica`,fontWeight:"bold",fontSize:"XXL"
        },
        picMedia: {
            height: "100%",
            paddingTop: "50%", // 16:9,
            margin:"0%",
            cursor:"pointer",
            objectFit:"contain",
            '&:hover':{
                transition: 'opacity 0.3s ease-in-out'
            },
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <WSBox py={3}>
                <Card style={styles.media}>
                    <WSTypography color={"error"} sx={{fontFamily: `Georgia`,fontWeight:"bold",fontSize:"XXL",marginBottom:"20px"}}>Hey,Hello ? World ?</WSTypography>
                    <CardMedia
                        image={helloworld} // require image
                        title= "Mehrdad Kheirmand"
                        style={styles.picMedia} // specify styles
                    />
                    <Divider/>
                    <WSTypography color={"error"} sx={{fontFamily: `Georgia`,fontWeight:"bold",fontSize:"XXL",marginBottom:"20px"}}>Introduction</WSTypography>
                    <WSTypography sx={{fontFamily: `Helvetica`}}>
                        "Hello, World! 'Hello, World!' just dropped by to say hi in the language of code.
                        Ready to party with some programming fun?"
                    </WSTypography>
                    <WSTypography color={"success"} sx={{fontFamily: 'Georgia'}}>
                        Let's Go ;))
                    </WSTypography>

                </Card>

            </WSBox>
        </DashboardLayout>

    )
}

export default HWTemplate;