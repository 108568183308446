
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import WSBox from "components/WSBox";

// Material Dashboard 2 React example components
import DashboardLayout from "modules/LayoutContainers/DashboardLayout";
import DashboardNavbar from "modules/Navbars/DashboardNavbar";
import Map from "./components/map";
import Divider from "@mui/material/Divider";

// Data

// Dashboard components
function Message() {

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <WSBox py={3}>
                <Map />
            </WSBox>

        </DashboardLayout>
    );
}

export default Message;
