

import { useEffect } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";


import WSBox from "components/WSBox";
import WSTypography from "components/WSTypography";
import WSButton from "components/WSButton";
import WSAvatar from "components/WSAvatar";

import SidenavCollapse from "modules/Sidenav/SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "modules/Sidenav/SidenavRoot";
import sidenavLogoLabel from "modules/Sidenav/styles/sidenav";

import CloseIcon from '@mui/icons-material/Close';
import GitHubIcon from '@mui/icons-material/GitHub';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import Logo1 from "assets/images/mehrdad-kheirmand-logo-1.png";
import Logo2 from "assets/images/mehrdad-kheirmand-logo-2.png";
import Logo3 from "assets/images/mehrdad-kheirmand-logo-3.png";

import onDownload from "../PdfDownloader";
import {
  useMaterialUIController,
  setMiniSidenav,
  setWhiteSidenav,
} from "context";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import {Helmet} from "react-helmet";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, href, route }) => {
    let returnValue;

    if (type === "collapse") {
      returnValue = href ? (
        <Link
          href={href}
          key={key}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavCollapse
            name={name}
            icon={icon}
            active={key === collapseName}
            noCollapse={noCollapse}
          />
        </Link>
      ) : (
        <NavLink key={key} to={route}>
          <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
        </NavLink>
      );
    } else if (type === "title") {
      returnValue = (
        <WSTypography
          key={key}
          color={textColor}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </WSTypography>
      );
    } else if (type === "divider") {
      returnValue = (
        <Divider
          key={key}
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
      );
    }

    return returnValue;
  });

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <WSBox pt={3} pb={1} px={4} textAlign="center">
        <WSBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <WSTypography variant="h6" color="secondary">
            <CloseIcon sx={{ fontWeight: "bold" }}/>
          </WSTypography>
        </WSBox>
        <WSBox component={NavLink} to="/" display="flex" alignItems="center">
          <WSAvatar src={Logo3} alt="profile-image" size="md" shadow="sm" />
          <WSBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <WSTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
              {brandName}
            </WSTypography>
          </WSBox>
        </WSBox>
      </WSBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>{renderRoutes}</List>
      <Divider />
      <WSBox  mt={2} textAlign="center" >
        <WSBox  justifyContent="center">
          <WSBox mb={3}>
            <WSButton
                component={Link}
                rel="noreferrer"
                color={sidenavColor}
                sx={{minWidth :"150px",maxWidth :"150px"}}
                onClick = {onDownload}
            >
              <Helmet><link rel="icon" href="%PUBLIC_URL%/mehrdad-kheirmand-logo.svg" /></Helmet>
             <InsertDriveFileIcon />
              &nbsp; Resume
            </WSButton>
          </WSBox>
          <WSButton
              component={Link}
              href="https://github.com/MehrdadsRobot"
              target="_blank"
              rel="noreferrer"
              color={sidenavColor}
              sx={{minWidth :"150px",maxWidth :"150px"}}
          >
            <GitHubIcon />
            &nbsp; Github
          </WSButton>
        </WSBox>
      </WSBox>
      <Divider />
      <WSBox mt={5} justifyContent={"center"}>
        <WSBox display={"flex"} flexDirection={"column"} alignItems="center" justifyContent={"center"}>
          <WSTypography fontFamily={"roboto"} >&copy; {new Date().getFullYear()} Copyright</WSTypography>
          <WSTypography fontFamily={"roboto"}>MehrdadKheirmand</WSTypography>
        </WSBox>
        <WSBox display={"flex"} justifyContent={"center"}>
          <WSTypography fontFamily={"times new roman"} >Powered By </WSTypography>
          <WSBox display={"flex"} justifyContent={"center"} ml={"10px"}>
            <GitHubIcon style={{ color: 'white',marginTop:'5px' }}/>
            <Link target={"_blank"} href="https://github.com/" ml={"5px"}>
              <WSTypography fontWeight= "small" fontFamily={"roboto"}>Github</WSTypography>
            </Link>
          </WSBox>
        </WSBox>
      </WSBox>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
