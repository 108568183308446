import {useState} from "react";
import usePagination from "../../methods/Pagination";
//import {default as data} from "../../data/Blog_Data.json";
import WSBox from "components/WSBox";
import WSTypography from "components/WSTypography";
import Icon from "@mui/material/Icon";

import TimelineItem from "modules/Timeline/TimelineItem";
import Card from "@mui/material/Card";
import {CardMedia, Pagination} from "@mui/material";

import DashboardNavbar from "../../modules/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";

import DashboardLayout from "../../modules/LayoutContainers/DashboardLayout";
import BlogCard from "./OverViewCard";
import rgba from "../../assets/theme-dark/functions/rgba";
import {Tag} from "@mui/icons-material";

import termux from "../../assets/images/termux-1.png";
import helloWorld from "../../assets/images/hello-world.png";
import helloWorld2 from "../../assets/images/bruce-mars.jpg";

import BlogData from "./BlogData";

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
} from "react-router-dom";
import BlogTemplate from "./BlogTemplates/Termux";


function Blog(){

    let [page, setPage] = useState(1);
    const PER_PAGE = 3;

    const count = Math.ceil(BlogData().length / PER_PAGE);
    const _DATA = usePagination(BlogData(), PER_PAGE);

    const handleChange = (e, p) => {
        setPage(p);
        _DATA.jump(p);
    };

    const X = {
        "termux" : require("../../assets/images/termux-1.png"),
        "helloworld": require("../../assets/images/hello-world.png"),
        "helloworld2": require("../../assets/images/mehrdad-kheirmand-logo-2.png")
    };

    const styles = {
        media: {
            height: "100%",
            padding: "56.25px", // 16:9,
            boxShadow:"10px 10px 5px 0px rgba(0,0,0,0.75)",
        }
    };
    return(
        <DashboardLayout>
            <DashboardNavbar />
            <WSBox py={3}>
                <WSBox mb={"10px"}>
                    {/*<Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={4}>
                            <BlogCard date={"March 2023"} title={"blah blah blah"} description={"Dlawbjfklabwkljdnbklajwbfklcjabdkjabwd"}/>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <BlogCard date={"March 2023"} title={"blah blah blah"} description={"Dlawbjfklabwkljdnbklajwbfklcjabdkjabwd"}/>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <BlogCard date={"March 2023"} title={"blah blah blah"} description={"Dlawbjfklabwkljdnbklajwbfklcjabdkjabwd"}/>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <BlogCard date={"March 2023"} title={"blah blah blah"} description={"Dlawbjfklabwkljdnbklajwbfklcjabdkjabwd"}/>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <BlogCard date={"March 2023"} title={"blah blah blah"} description={"Dlawbjfklabwkljdnbklajwbfklcjabdkjabwd"}/>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <BlogCard date={"March 2023"} title={"blah blah blah"} description={"Dlawbjfklabwkljdnbklajwbfklcjabdkjabwd"}/>
                        </Grid>
                    </Grid>*/}

                    <Grid container spacing={3}>
                        {_DATA.currentData().map(v => {
                            return (
                                <Grid item xs={12} md={6} lg={4}>
                                    <BlogCard picture ={X[v.picture]}
                                              date={v.date} title={v.title} description={v.summary}
                                              link={v.link}
                                              tag = {v.tag}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                </WSBox>
                <Pagination count={count} color="secondary" page={page} onChange={handleChange}/>
            </WSBox>
        </DashboardLayout>
    );
}


export default Blog;