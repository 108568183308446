import WSBox from "components/WSBox";
import WSTypography from "components/WSTypography";
import Icon from "@mui/material/Icon";
import TimelineItem from "modules/Timeline/TimelineItem";
import Card from "@mui/material/Card";
import {CardMedia} from "@mui/material";
import brucemars from "assets/images/bruce-mars.jpg";
import Divider from "@mui/material/Divider";
function JobExperience(){
    const styles = {
        media: {
            height: "100%",
            padding: "30px", // 16:9,
            boxShadow:"10px 10px 5px 0px rgba(0,0,0,0.75)",
            opacity:"0.95"
        }
    };
    return(
            <Card style={styles.media}>
                <WSTypography mb={"20px"} color={"error"} sx={{fontFamily: `Georgia`,fontWeight:"bold",fontSize:"XXL"}}>Experiences</WSTypography>

                <WSTypography sx={{fontFamily: `Helvetica`,fontSize:"XXL"}}>Iran's Electronic Industries(Shiraz Branch)</WSTypography>
                <WSTypography sx={{fontFamily: `Helvetica`,fontSize:"XXL"}}>Sept 2021 - Aug 2023</WSTypography>
                <WSTypography sx={{fontFamily: `Helvetica`,fontSize:"XXL"}}>Shiraz - Iran</WSTypography>
                <WSTypography sx={{fontFamily: `Helvetica`,fontSize:"XXL"}}>As a software engineer, Team leader and scrum master,
                    Web developer and analyzer.</WSTypography>
                <Divider/>
                <WSTypography sx={{fontFamily: `Helvetica`,fontSize:"XXL"}}>New World Business Group(Arioobarzan development team)</WSTypography>
                <WSTypography sx={{fontFamily: `Helvetica`,fontSize:"XXL"}}>Aug 2019 - Aug 2021</WSTypography>
                <WSTypography sx={{fontFamily: `Helvetica`,fontSize:"XXL"}}>Shiraz - Iran</WSTypography>
                <WSTypography sx={{fontFamily: `Helvetica`,fontSize:"XXL"}}>As Mobile applications developer, data engineer, Image
                    processing and machine learning researcher.</WSTypography>
                <Divider />
                <WSTypography sx={{fontFamily: `Helvetica`,fontSize:"XXL"}}>FreeLancer</WSTypography>
                <WSTypography sx={{fontFamily: `Helvetica`,fontSize:"XXL"}}>Oct 2017 -  July 2019</WSTypography>
                <WSTypography sx={{fontFamily: `Helvetica`,fontSize:"XXL"}}>Shiraz - Iran</WSTypography>
                <WSTypography sx={{fontFamily: `Helvetica`,fontSize:"XXL"}}>As Mobile applications developer,Windows applications developer, different projects
                    concerning reverse engineering, Api Design and Web Scraping.  </WSTypography>
                <Divider />
                <WSTypography color={"error"} sx={{fontFamily: `Georgia`,fontWeight:"bold",fontSize:"XXL"}}>Reasearch And Publications</WSTypography>
                <WSTypography sx={{fontFamily: `Helvetica`,fontSize:"XXL"}}>Analyzing Security Counter Measures and protocols for the Internet Of Things(IOT): A survey</WSTypography>
                <WSTypography sx={{fontFamily: `Helvetica`,fontSize:"XXL"}}>A survey of different applied protocols for improving
                    security in the layers of interconnected devices via
                    different media and simulate an attack in a virtual
                    environment.</WSTypography>
            </Card>
    );
}


export default JobExperience;