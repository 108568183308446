import WSBox from "components/WSBox";
import WSTypography from "components/WSTypography";
import Icon from "@mui/material/Icon";
import TimelineItem from "modules/Timeline/TimelineItem";
import Card from "@mui/material/Card";
import {CardMedia} from "@mui/material";
import brucemars from "assets/images/bruce-mars.jpg";
import Divider from "@mui/material/Divider";
function Projects(){
    const styles = {
        media: {
            height: "100%",
            padding: "30px", // 16:9,
            boxShadow:"10px 10px 5px 0px rgba(0,0,0,0.75)",
            opacity:"0.95"
        }
    };
    return(
        <Card style={styles.media}>
            <WSTypography mb={"20px"} color={"error"} sx={{fontFamily: `Georgia`,fontWeight:"bold",fontSize:"XXL"}}>Projects</WSTypography>
            <WSTypography sx={{fontFamily: `Helvetica`,fontSize:"XXL"}}>Analyzing Instagram application’s private API request (Reverse Engineering)</WSTypography>
            <WSTypography sx={{fontFamily: `Helvetica`,fontSize:"XXL"}}>2021</WSTypography>
            <WSTypography sx={{fontFamily: `Helvetica`,fontSize:"XXL"}}>Capturing Instagram requests and
                researching about the application’s
                behavioural patterns by burpSuite and
                bypassing SSL pinning using a simulated
                device.
            </WSTypography>
            <Divider />
            <WSTypography sx={{fontFamily: `Helvetica`,fontSize:"XXL"}}>Back-end development of ‘runimoo.com’ a gaming website (currently down)</WSTypography>
            <WSTypography sx={{fontFamily: `Helvetica`,fontSize:"XXL"}}>2020</WSTypography>
            <WSTypography sx={{fontFamily: `Helvetica`,fontSize:"XXL"}}>developed the back-end and designed the
                database structure. also contributed to the
                level design applying gamification methods.
            </WSTypography>
            <Divider />
            <WSTypography sx={{fontFamily: `Helvetica`,fontSize:"XXL"}}>Complete designing and development of Data management panel.</WSTypography>
            <WSTypography sx={{fontFamily: `Helvetica`,fontSize:"XXL"}}>2021 - 2022</WSTypography>
            <WSTypography sx={{fontFamily: `Helvetica`,fontSize:"XXL"}}>development of a management panel for the
                company using react.js and material-ui.
            </WSTypography>
            <Divider />
            <WSTypography sx={{fontFamily: `Helvetica`,fontSize:"XXL"}}>Complete designing and development of back-end for
                sentiment-analysis panel</WSTypography>
            <WSTypography sx={{fontFamily: `Helvetica`,fontSize:"XXL"}}>2021 - 2023</WSTypography>
            <WSTypography sx={{fontFamily: `Helvetica`,fontSize:"XXL"}}>full development of a back-end panel for
                managing enormous amount of data in
                NoSQL mongoDB database and .NetCore API
                development environment.
            </WSTypography>
            <Divider />
            <WSTypography sx={{fontFamily: `Helvetica`,fontSize:"XXL"}}>Designing and training a CNN for detecting
                vehicle registration plate. (Western Arabic numerals)</WSTypography>
            <WSTypography sx={{fontFamily: `Helvetica`,fontSize:"XXL"}}>2021</WSTypography>
            <WSTypography sx={{fontFamily: `Helvetica`,fontSize:"XXL"}}>developing a digit recognition system using
                openCV and Tensorflow for detecting
                numbers demonstrated on a video stream or
                a picture with image processing methods.
            </WSTypography>

        </Card>
    );
}


export default Projects;